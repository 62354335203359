.CollectionForm {
  table {
    th.field-title {
      padding-top: 1em; // wack.
    }
    td {
      max-width: 600px;
    }
  }

  .modifier {
    margin-bottom: 1em;
  }

  .modifier.inherited {
    cursor: not-allowed;
  }

  .field-input {
    & {
      min-width: 100%;
    }

    textarea {
      width: 100%;
      max-width: 100%;
    }
  }

  p {
    margin-top: 2em;
  }
}
