.CollectionListEditSearch {
  .initial-help {
    padding: 10px;
  }

  .search-callout {
    & {
      margin-top: 10px;
      display: flex;
      gap: 10px;
    }

    .image-box {
      min-width: 100px;
      min-height: 100px;
      text-align: center;
    }

    .item-details {
      td, th {
        padding: 2px;
      }
    }
  }
}
