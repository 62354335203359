.ItemAdd {
  & {
    margin-bottom: 1em;
  }

  .header {
    margin-bottom: 1em;
    font-size: 150%;
    font-weight: bold;
  }

  .form-fields {
    & {
      column-count: 1;
    }

    @media only screen and (min-width: 650px) {
      column-count: 2;
    }

    @media only screen and (min-width: 1500px) {
      column-count: 3;
    }

    @media only screen and (min-width: 2000px) {
      column-count: 4;
    }

    .bp4-form-group {
      & {
        display: flex;
      }

      @media only screen and (max-width: 600px) {
        width: 100%;
      }

      .bp4-label {
        & {
          width: 120px;
          flex-shrink: 0;
        }

        @media only screen and (max-width: 600px) {
          width: 90px;
        }
      }

      .bp4-form-content {
        flex-grow: 2;
      }
    }
  }

  .button-row {
    button {
      margin-right: 1em;
    }
  }

  .image-box {
    & {
      padding: 2px;
      display: inline-block;
    }

    & > * {
      vertical-align: middle;
    }

    img {
      max-width: 50px;
      max-height: 50px;
    }
  }
}
