.book-cover {
  & {
    width: 100%;
    text-align: center;
  }

  img {
    max-width: 100px;
    max-height: 100px;
  }
}
