.ItemList {
  .item-table-wrapper {
    width: 100%;
    overflow-x: auto;
  }

  // .item-table {
  //   min-width: 100%;
  // }

  .image-thumb {
    max-width: 100px;
    max-height: 100px;
  }

  .header-label {
    font-weight: bold;
    color: inherit;
    white-space: nowrap;
  }

  .controls {
    display: flex;
    align-items: baseline;
    margin-bottom: 1em;
    margin-top: 1em;
  }

  .item-view {
    padding: 0.5em 0;
  }
}
