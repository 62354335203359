.CollectionListItemEditRow {
  & {
    margin-bottom: 10px;
  }

  .main-form {
    display: flex;
    gap: 10px;
  }

  &.marked-for-delete {
    opacity: 0.3;
  }

  .thumbnail-box {
    flex-grow: 0;
    text-align: center;
    min-height: 100px;
    min-width: 100px;
  }

  .edit-form {
    & {
      flex-grow: 2;
    }

    .form-field .bp4-form-content {
      flex-grow: 2;
    }
  }

  .controls-box {
    & {
      width: 50px;
    }
    .index {
      font-weight: bold;
    }
  }

  .matches {
    .matching-item-row {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }

  .button-row {
    margin-top: 10px;
    margin-bottom: -25px;
    text-align: left;
  }
}
