@import "normalize.css/normalize.css";
// @import "node_modules/@blueprintjs/core/src/blueprint.scss";
// @import "node_modules/@blueprintjs/icons/src/blueprint-icons.scss";
@import "@blueprintjs/core/lib/css/blueprint.css";
@import "@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "@blueprintjs/select/lib/css/blueprint-select.css";
@import "./vars";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bg;
  color: $fg;
}

.bp4-portal {
  z-index: 200;
}

.overlay-card {
  & {
    width: 600px;
    left: calc(50vw - 300px);
    top: 10vh;
  }

  @media only screen and (max-width: 600px) {
    top: 0;
  }
}

.shrinkfix {
  & {
    flex-shrink: 1;
  }

  & > * {
    flex-shrink: 1;
  }
}

.shrinkfix-0 {
  flex-shrink: 0;
}

.growfix-2 {
  flex-grow: 2;
}
