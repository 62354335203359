.Page.CollectionListDetail {
  .completion {
    & {
      display: flex;
      gap: 10px;
      margin-bottom: 10px;
    }

    .header {
      font-weight: bold;
    }

    .CompletionBar {
      flex-grow: 2;
    }
  }

  .item-table {
    min-width: 100%;
    overflow-x: scroll;
  }

  .match-table {
    margin: -10px;
  }

  .toggle-buttons {
    margin-bottom: 3px;
  }

  .owner-controls {
    margin-bottom: 3px;
    margin-left: 10px;
  }
}
