.item-row {
  .modifier {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 3px;
    margin-right: 1em;
    font-size: 80%;
    white-space: nowrap;
  }
}

.child-row {
  & > td {
    padding: 0 !important;
  }

  table {
    width: 100%;
  }
}
