.Footer {
  & {
    margin-top: 2rem;
    font-size: 75%;
    padding: 10px
  }

  .build-info {
    margin-left: 20px;
    opacity: 0.25;
  }
}
