.image-thumb {
  & {
    display: inline-block;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(0, 0, 0, 0.3);
  }

  img {
    max-height: 100%;
    max-width: 100%;
    cursor: pointer;
  }
}

.ItemThumbnail-modal {
  .header {
    & {
      display: flex;
      align-items: flex-start;
    }

    .title {
      align-self: flex-start;
      flex-grow: 2;
      font-size: 150%;
    }

    .close-button {
      align-self: flex-end;
    }
  }

  .main-image {
    & {
      display: flex;
    }

    .image-full {
      & {
        display: inline-block;
        flex-grow: 2;
        text-align: center;
      }

      img {
        min-height: 400px;
        max-height: 600px;
      }
    }
  }
}
