.Pagination {
  & {
    display: flex;
    align-items: baseline;
    margin-right: 2em;
  }

  .page-label {
    margin-right: 1em;
  }

  .per-page-label {
    margin-left: 2rem;
    margin-right: 1em;
  }
}
