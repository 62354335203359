@import "../../vars";

.Page.CollectionImport {
  textarea {
    font-family: $mono-font;
    min-height: 400px;
  }

  .modifier {
    margin-right: 0.5em;
  }
}

.import-modal {
  .overlay-card {
    .progress-element {
      margin: 0.5em 0;
    }
  }

  .import-messages {
    & {
      height: 300px;
      padding: 0.5em;
      border: 1px solid $dark-gray3;
      background-color: $dark-gray2;
      overflow-y: scroll;
    }
    .message {
      font-family: $mono-font;
      font-size: 80%;
    }
    .message.success {
      color: $green4;
    }
    .message.error {
      color: $red4;
    }
  }
}
