.CollectionListForm {
  .form-row {
    & {
      max-width: 600px;
    }
    .bp4-html-select {
      width: 100%;
    }
    select {
      display: block;
      // width: 100% !important;
    }
    label {
      width: 120px;
      font-weight: bold;
      flex-shrink: 0;
    }
    textarea {
      width: 100%;
    }
    .bp4-form-content {
      flex-grow: 2;
    }
  }
  .button-row {
    padding-left: 130px;
    display: flex;
    gap: 10px;
  }
}
