.Page.CollectionLists {
  .list-row {
    padding: 5px;
    display: flex;
    gap: 10px;
  }

  .list-table {
    & {
      width: 100%;
      border-spacing: 10px;
    }

    .list-title {
      width: 120px;
    }

    .list-completion {
      width: 100%;
    }

    @media only screen and (min-width: 601px) {
      .list-title {
        white-space: nowrap;
      }
    }
  }

  .list-item-count {
    white-space: nowrap;
  }

  .list-description {
    opacity: 0.75;
    font-style: oblique;
  }

  .list-title {
    font-weight: bold;
  }

  .list-description, .list-completion {
    flex-grow: 2;
  }
}
