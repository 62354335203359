@import "../../vars";

.ButtonLink {
  & {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    color: $link;
    cursor: pointer;
  }

  &:hover {
    color: $link-hover;
  }
}
