@import "../../vars";

#BreadcrumbBar {
  & {
    padding: 5px 10px;
    border-radius: 0;
  }

  a,a:visited {
    color: $fg;
  }

  a:hover,a:active {
    color: $link;
  }
}
