@import "./vars";

code {
  font-family: $mono-font;
}

.App a,a:visited {
  color: $link;
  text-decoration: underline;
}

.App a:hover,a:active {
  color: $link-hover;
  text-decoration: none;
}

.App a.bp4-button {
  text-decoration: none;
}

.App header {
  font-size: 130%;
  font-weight: bold;
}

.Page {
  margin: 10px;
}

.bp4-overlay-content {
  z-index: 200 !important;
}

@media only screen and (min-width: 601px) {
  .mobile-only {
    display: none !important;
  }
}

@media only screen and (max-width: 600px) {
  .mobile-hidden {
    display: none !important;
  }

  .bp4-card {
    padding: 10px;
    margin: 0;
  }
}
