.CompletionBar {
  & {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
  }

  .bar {
    flex-grow: 2;
  }

  .ratio {
    min-width: 70px;
  }

  .percent {
    min-width: 45px;
  }
}
