.Page.CollectionListItemsEdit {
  & {
    display: flex;
    // justify-content: stretch;
    gap: 10px;
    flex-wrap: wrap;
  }

  @media only screen and (min-width: 601px) {
    & {
      max-height: 800px;
    }

    #search-pane, #items-pane {
      overflow-y: auto;
    }
  }

  #search-pane {
    min-width: 500px;
    width: 600px;
  }

  #items-pane {
    flex-grow: 2;
    min-width: 500px;
    max-height: 800px;
  }

  .summary-box {
    & {
      margin-top: 10px;
    }

    .summary-buttons {
      margin-top: 10px;
      display: flex;
      gap: 10px;
    }
  }
}
