.Page.Home {
  @media only screen and (max-width: 600px) {
    .card {
      margin-bottom: 10px;
    }
  }

  @media only screen and (min-width: 601px) {
    & {
      display: flex;
    }

    .card {
      width: 600px;
      margin-right: 10px;
    }
  }

  .card {
    header {
      font-size: 130%;
      font-weight: bold;
    }
  }
}
