.ItemDetail {
  & > * {
    display: inline-block;
    vertical-align: top;
    margin-right: 1em;
  }

  .field-title {
    font-weight: bold;
  }

  .image-thumb {
    margin-right: 1px;
  }

  .controls {
    button {
      margin-left: 0.5em;
    }
    th, td {
      text-align: right;
    }
  }
  
  .field-sub {
    font-size: 75%;
    margin-left: 1em;
  }

  .modifier {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 3px;
    margin-right: 1em;
    font-size: 80%;
    white-space: nowrap;
  }

  .image-edit-row {
    & {
      display: flex;
      align-items: center;
      padding: 1px;
    }
    .image-added {
      padding: 0 10px;
      flex-grow: 2;
    }
    .image-box {
      width: 50px;
      max-height: 50px;
      text-align: center;
    }
    img {
      max-width: 50px;
      max-height: 50px;
    }
  }
}
