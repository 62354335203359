// @import "@blueprintjs/colors/lib/scss/colors";
@import "@blueprintjs/core/lib/scss/variables.scss";

// $bg: #333;
$bg: $pt-dark-app-background-color;
$bg-inner: $dark-gray4;
$fg: $pt-dark-text-color;
$fg-minor: $pt-dark-text-color-muted;
$link: $blue5;
$link-hover: $blue4;
$error-bg: $vermilion1;
$error-fg: $fg;
$mono-font: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
