@import "../../vars";

#Header {
  & {
    position: sticky;
    padding: 10px;
    // background-color: $bg-inner;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    font-size: 150%;
    // box-shadow: 0 0 10px #000;
    border-radius: 0;
    z-index: 90;
  }

  & > * {
    display: inline-block;
  }

  .username {
    margin-right: 10px;
    font-size: 70%;
  }

  @media only screen and (max-width: 600px) {
    .username {
      display: none;
    }
  }

  .home-link {
    color: $fg;
    font-weight: bold;
    text-decoration: none;
  }

  .spacer {
    flex-grow: 2;
  }

  .header-link {
    margin-left: 10px;
    color: inherit;
    text-decoration: none;
  }
}
