@import "../../vars.scss";

.UserCollectionList {
  .collection-row {
    .main-row {
      & {
        padding: 4px;
      }

      .collection-name {
        font-weight: bold;
        margin-right: 0.5em;
      }
    }
    .secondary-row {
      & {
        padding-left: 8px;
        color: $fg-minor;
        font-size: 75%;
      }
    }
  }
  .add-button {
    margin-top: 1em;
  }
}
