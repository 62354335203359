@import "../../vars.scss";

.ErrorBar {
  & {
    position: fixed;
    height: 0px;
    overflow: visible;
    width: 100%;
    z-index: 70;
  }

  .ButtonLink {
    color: inherit;
  }

  .ErrorBanner {
    & {
      background-color: $error-bg;
      color: $error-fg;
      width: 100%;
      padding: 10px;
      box-shadow: 0 5px 10px -5px #000;
    }

    .main-row {
      display: flex;
    }

    .message {
      margin-left: 10px;
      flex-grow: 2;
    }

    .close-button {
      align-self: flex-end;
    }
  }

  .close-all {
    & {
      text-align: right;
    }

    .close-all-button {
      background-color: $error-bg;
      padding: 10px;
      border-bottom-left-radius: 10px;
      box-shadow: -5px 5px 10px -5px #000;
    }
  }
}
